import PropTypes from 'prop-types';
import React , {useState} from 'react';
import $ from 'jquery'

// material-ui
import { makeStyles } from '@material-ui/styles';

// assets
import { InPageConnectButton } from './ConnectButton';
import { Link } from 'react-router-dom';
import { Container } from '@material-ui/core';


//-----------------------|| MAIN NAVBAR / HEADER ||-----------------------//


const Header = () => {
    const [mobScroll , setMobScroll] = useState(false);

    $(window).on("scroll", function() {
        if($(window).scrollTop() > 30) {
            $(".style_header__gUxsQ").addClass("active");
            setMobScroll(true)
        } else {
            //remove the background property so it comes transparent again (defined in your css)
           $(".style_header__gUxsQ").removeClass("active");
           setMobScroll(false)
        }
    });

    return (
        
        <header className="style_header__gUxsQ">
            <Link to={"/"}>
                <img 
                    src="assets/media/seven_white.png" 
                    className="style_logo__QGaEE cmLogo"
                    alt="Seven-Chain"
                    style={{backgroundColor : mobScroll ? "#FF0000" : "inherit"  }}
                />
            </Link>
            <div>
                <InPageConnectButton />
            </div>
        </header>     
       
    );
};


Header.propTypes = {
    handleLeftDrawerToggle: PropTypes.func
};

export default Header;
