import React from 'react';


import { ThemeProvider } from '@material-ui/core/styles';
import { CssBaseline, StyledEngineProvider } from '@material-ui/core';
import {
    darkTheme,
    RainbowKitProvider
} from "@rainbow-me/rainbowkit";
import { chains } from './hooks/wagmi';


// routing
import Routes from './routes';

// defaultTheme
import theme from './themes';

// project imports
import NavigationScroll from './layout/NavigationScroll';


//-----------------------|| APP ||-----------------------//

const App = () => {

    return (
        <StyledEngineProvider injectFirst>
                <RainbowKitProvider
                    appInfo={{
                        appName: 'Staking'
                    }}
                    chains={chains}
                    theme={darkTheme({
                        accentColor: '#7b3fe4',
                        accentColorForeground: 'white',
                        borderRadius: 'small',
                        fontStack: 'system',
                        overlayBlur: 'small',
                      })}
                    >
                    <ThemeProvider theme={theme()}>
                        <CssBaseline />
                        <NavigationScroll>
                            <Routes />
                        </NavigationScroll>
                    </ThemeProvider>
                </RainbowKitProvider>
        </StyledEngineProvider>
    );
};

export default App;
