import en from '../language/en';
import ja from '../language/ja';
// import enMx from '../../language/es-mx';
// import ko from '../../language/ko';
// import zh from '../../language/zh';

export const language = {
  English: en,
  Japanese: ja,

};
