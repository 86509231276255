import React , {useEffect , useState}from 'react';
import { makeStyles } from '@material-ui/styles';
import { Link } from 'react-router-dom';
import Grid from "@material-ui/core/Grid";
import TelegramIcon from '@mui/icons-material/Telegram';
import TwitterIcon from '@mui/icons-material/Twitter';
import { IconLetterM } from '@tabler/icons';
import { language } from "../pages/language";


var localStorageLang = localStorage.getItem("lang");
if (localStorageLang === null) {
  localStorageLang = "Japanese";
}


const Footer = () => {

  const [windowWidth , setWindowWidth] = useState(getWindowWidth())

  // const matches = useMediaQuery('(min-width:900px)');

  useEffect(() => {
      function handleWindowResize() {
          setWindowWidth(getWindowWidth());
        }
        window.addEventListener('resize', handleWindowResize);
        return () => {
          window.removeEventListener('resize', handleWindowResize);
        };
  }, [])

  function getWindowWidth() {
      const {innerWidth} = window;
      return {innerWidth};
    }

    return (
        <>
               <div className="row">
                    <Grid container className='footer_styles' sx={{ display:'flex', justifyContent:'center' , marginLeft:'10px' , marginRight:'10px'}}>
                      { windowWidth.innerWidth < '900'  ? (
                        <>
                         <Grid item sx={{ width:{ xs:1, md:1/2}}} >
                            <h3 style={{color:'white'}}> {language[localStorageLang].DISCLAIMER}  </h3>
                            <h5 style={{color:'white'}}> {language[localStorageLang].DISCLAIMER_CONTENT} </h5>
                         </Grid>
                        <Grid item sx={{ width:{ xs:1, md:1/2}}}>
                                <Link to={"/"}>
                                 <img 
                                    src="assets/media/seven_white.png" 
                                    className="style_logo__QGaEE"
                                    alt="Seven-Chain"
                                    style={{height:'50px' , width:'50px'}}
                                 />
                                </Link>
                            <br/>
                            <h3 style={{color:'white'}}>Seven Chain © {language[localStorageLang].COPYRIGHT} 2023.{language[localStorageLang].RIGHTS}</h3>
                            <div className='icon-separate'>
                              <Link to={{pathname:""}}><span fontSize="small" style={{color:"white"}}><TelegramIcon/></span> </Link>
                              <Link to={{pathname:""}} ><span fontSize="small" style={{color:"white"}}><TwitterIcon/></span> </Link>
                              <Link to={{pathname:""}} ><span fontSize="small" style={{color:"white"}}><IconLetterM/></span></Link>
                            </div>
                      </Grid>
                      </>
                      ) : (
                        <>
                          <Grid item sx={{ width:{ xs:1, md:1/2}}}>
                                 <Link to={"/"}>
                                  <img 
                                     src="assets/media/seven_white.png" 
                                     className="style_logo__QGaEE"
                                     alt="Seven-Chain"
                                     style={{height:'50px' , width:'50px'}}
                                  />
                                 </Link>
                             <br/>
                             <h3 style={{color:'white'}}>Seven Chain © {language[localStorageLang].COPYRIGHT} 2023.{language[localStorageLang].RIGHTS}</h3>
                             <div className='icon-separate'>
                               <Link to={{pathname:""}} ><span fontSize="small" style={{color:"white"}}><TelegramIcon/></span> </Link>
                               <Link to={{pathname:""}}  ><span fontSize="small" style={{color:"white"}}><TwitterIcon/></span> </Link>
                               <Link to={{pathname:""}}  ><span fontSize="small" style={{color:"white"}}><IconLetterM/></span></Link>
                             </div>
                          </Grid>
                          <Grid item sx={{ width:{ xs:1, md:1/2}}} >
                            <h3 style={{color:'white'}}> {language[localStorageLang].DISCLAIMER}  </h3>
                            <h5 style={{color:'white'}}> {language[localStorageLang].DISCLAIMER_CONTENT} </h5>
                          </Grid>
                        </>
                      )}
                    </Grid>
            </div>
        </>
    )
}

export default Footer;