let ENV = 'prod'

let game_token= "CCC (Crypto Casino Coin)"
let game_token_symbol = "CCC"
let game_token_decimals = 18
// let game_token_image = 'https://foo.io/token-image.svg'
let game_token_image = 'https://i.ibb.co/GPT3WFx/ccc-logo.png'
let min_stake_amount = 10
let apy = "30%"
let game_token_requirement =  200000
let eth_b2zswap = "https://www.b2z.exchange/en/trading/SVC-USDT"
let matic_b2zswap = "https://www.b2z.exchange/en/trading/SVC-USDT"

let config;

if(ENV === 'test' || ENV === 'dev'){
    config = {
        // basename: only at build time to set, and don't add '/' at end off BASENAME for breadcrumbs, also don't put only '/' use blank('') instead,
        // like '/berry-material-react/react/default'
        basename: '',
        defaultPath: '/',
        fontFamily: `Square`,
        borderRadius: 12,
        API_SERVER: 'http://localhost:5000/api/',
        // API_URL : 'http://0.0.0.0:5811', //local
        // API_URL : "https://83f7-27-116-16-50.ngrok.io",
        API_URL : "https://ceewen.xyz:5811", //test server
        GAME_TOKEN: game_token,
        GAME_TOKEN_ADDRESS : "0x803484B7562c96718b9C72b7CA5d00451D7Cee69", //matic testnet
        GAME_TOKEN_SYMBOL : game_token_symbol,
        GAME_TOKEN_DECIMALS : game_token_decimals,
        GAME_TOKEN_IMAGE : game_token_image,
        MIN_STAKE_AMOUNT: min_stake_amount,
        APY: apy,
        GAME_TOKEN_REQUIREMENT: game_token_requirement,
        ETH_B2ZSWAP: eth_b2zswap,
        MATIC_B2ZSWAP: matic_b2zswap,
        env : ENV,
     }
    } else if(ENV === 'prod'){
        config = {
            // basename: only at build time to set, and don't add '/' at end off BASENAME for breadcrumbs, also don't put only '/' use blank('') instead,
            // like '/berry-material-react/react/default'
            basename: '',
            defaultPath: '/',
            fontFamily: `Square`,
            borderRadius: 12,
            API_SERVER: 'http://localhost:5000/api/',
            // API_URL : 'http://0.0.0.0:5811', //local
            // API_URL : "https://83f7-27-116-16-50.ngrok.io",
            API_URL : "https://staking.seven-project.com:5811", //prod server
            GAME_TOKEN: game_token,
            GAME_TOKEN_ADDRESS : "0x7A176aEbF62a6EF594cD0A8d7929Bf7A3d2262B2", //matic mainnet
            GAME_TOKEN_SYMBOL : game_token_symbol,
            GAME_TOKEN_DECIMALS : game_token_decimals,
            GAME_TOKEN_IMAGE : game_token_image,
            MIN_STAKE_AMOUNT: min_stake_amount,
            APY: apy,
            GAME_TOKEN_REQUIREMENT: game_token_requirement,
            ETH_B2ZSWAP: eth_b2zswap,
            MATIC_B2ZSWAP: matic_b2zswap,
            env : ENV,
    }
}

export default config;
