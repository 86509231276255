import config from "../../../config";

const en = {
    // HEADER PAGE
    CONNECT : "Connect",
    WRONG_NETWORK : "Wrong Network",

    //MAIN PAGE
    SVC_TOKEN_POOL : "SVC Token Pool",
    CIRCULATION_SUPPLY : "Circulating Supply",
    OF_SUPPLY : "of Total Supply",
    OF : "Of",
    TOTAL_LOCKED_SVC : "Total SVC Locked",
    TOTAL_SVC_STAKED : "Total SVC Staked",
    POOL_APY : "Pool APY",
    STAKE_SVC : "Stake SVC on pool",
    BUY_ON_B2Z : "Buy SVC on B2Z",
    STAKING_SVC : "Stake SVC to earn SVC",
    CURRENT_SVC_BAL : "Current SVC Balance",
    ESTIME_APY : "Estimated APY",
    AVAILABLE_DEPOSIT : "Available to deposit",
    MAX : "MAX",
    APPROVE: "Approve",
    STAKE : "Stake",
    UNCLAIMED_REWARDS : "Unclaimed Rewards",
    CLAIM_REWARDS : "claim reward",
    TOTAL_STAKED : "Total Staked",
    WITHDRAW_ALL : "Withdraw All",
    MILLION : "Million",
    BILLION : "Billion",
    HOME : "Home",
    PG_NOFOUND : "Page Not Found",
    CLICK_TOHOME : "Click Here to Go Back to Home Page",
    STAKE_AMT : "STAKED AMOUNT",
    STAKE_DATE : "STAKED DATE",
    REWARD_AMT : "REWARD AMOUNT",
    TX_HASH : "TRANSACTION HASH",
    TX_COPIED : "Transaction Hash Copied to Clipboard",
    CPY_HASH : "Copy Transaction Hash",
    CCC_IMPORT : "Import CCC",
    NO_RECORDS : 'No Records found',
    ROWS : "Rows :",
    S_NO : "S.No",
    OF : "of",

    //FOOTER
    COPYRIGHT : "Copyright ",
    RIGHTS : "All Rights Reserved",
    DISCLAIMER : "Disclaimer",
    DISCLAIMER_CONTENT : "All the information on this website or other official channels is published for information purposes only and is only intended for institutional investors and sophisticated individual investors. Any services to be provided in the future will be subject to the terms of the legal agreements relating thereto. Nothing on this Site should be interpreted as the investment endorsement by Seven Chain Network or any other person. Seven Chain Network and its related services are not provided to any individual from the United States. Seven Chain Network aims to build a cross-chain platform powering the data economy on Polkadot.",

    //MESSAGES - STAKING:
    msg1 : "You cannot stake 0",
    msg2 : "You cannot exceed your Balance Limit",
    msg3 : "Successfully Approved",
    msg4 : "Error in Approving , Please try again",
    msg5 : "Claimed Rewards Successfully",
    msg6 : "Staked Amounts Claimed  Successfully",
    msg7 : "Error in Fetching Staked SVC , Please try again",
    msg8 : "Staking Amount should be equal to",
    msg9 : "Successfully Staked",
    msg10 : "Your Staked Amount should be equal to ",
    STAKING_REJECTED: "Staking Rejected , Please try again",
    MIN_STAKE_ERROR: "Minimum Stake Amount",
    APPROVE_REJECT_ERROR: "Approve Rejected , Please try again",
    APPROVE_FAILED_ERROR: "Approve Failed , Please try again",
    USER_REJECTED_ERROR: "User Rejected Staking Amount Withdrawl",
    USER_REJECTED_CLAIM_REWARDS: "User Rejected Claim Rewards Withdrawl",
    APPROVE_TRANSACTION: "Please Approve Transaction from Connected Wallet",
    RECEIVED: "received",
    GAME_TOKEN_REWARD_TEXT: "Stake more than "+config.GAME_TOKEN_REQUIREMENT+" SVC to receive equal amount of "+config.GAME_TOKEN,
    GAME_TOKEN_RECEIVED: "You have Received ",
    GAME_TOKEN_RECEIVED2: " Click Here To View Your Rewards history",

  }
  export default en;